import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";


export function testingUseEffect(selectedFile){

    useEffect(()=>{
        console.log("test-----3", selectedFile)
    }, [selectedFile])
}

 