import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import DataTable from "./dataTable";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import {
  CardImg, CardText,
  CardTitle, CardSubtitle
} from 'reactstrap';

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";

import Progress from "./components/Progress";


import {
  Actions,
  DomainName,
  PubExclusive,
  Hosted,
  PublisherId,
  PublisherName,
  Registrar,
  Status,
  LandersCount,
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";

import EditModal from './EditModal';

import { config } from "../../config";
import CSVExportButton from "./CSVExportButton";



const DomainConfig = (props) => {

  const { buyers, fileList, lineChart, dispLoading } = props
  const { BACKEND_URL } = config

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [domain, setDomain] = useState();

  const [domainList, setDomainList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  const [datos, setDatos] = useState(null);


  const toggle = () => setModal(!modal);

  const getData = async () => {
    let url = `${BACKEND_URL}/domain/get-list`
    let { data } = await axios.get(url)

    console.log("data", data)

    if (data?.success) {
      const { domainList } = data

      setDomainList(domainList)
    }

    // setDomainList
  }


  // Función para manejar el cambio en el input de búsqueda
  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearch(newValue);
    // console.log("data:", domainList[0]);
    // Filtrar la lista basándose en el campo 'domain_name'
    const filteredList = domainList.filter(item => {

      const available = (item?.available) ? "Available" : "Not Available"

      return item?.domain_name?.toLowerCase().includes(newValue.toLowerCase())
        || available.toLowerCase().includes(newValue.toLowerCase())
        || item?.registrar?.toLowerCase().includes(newValue.toLowerCase())
        || item?.publisher_name?.toLowerCase().includes(newValue.toLowerCase())

    }
    );

    // Actualizar la lista filtrada
    setFilteredList(filteredList);
  };

  useEffect(() => {

    setFilteredList(domainList);

  }, [domainList])


  const getPublisherList = async () => {
    let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
    let { data } = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })

    if (data) {
      const { publishers } = data
      setPublisherList(publishers)
    }

  }

  const onCreate = async (value) => {
    let url = `${BACKEND_URL}/domain/create`
    let { data } = await axios.post(url, value)

    if (data?.success) {
      await getData()
    }
    // setDomainList
  }
  const onUpdate = async (values) => {
    const { id } = values
    let url = `${BACKEND_URL}/domain/update/${id}`
    let { data } = await axios.put(url, values)


    if (data?.success) {
      await getData()
    }
  }

  const deleteById = async (id) => {
    let url = `${BACKEND_URL}/domain/delete/${id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      await getData()
    }

    // setDomainList
  }


  const addModal = async (data) => {
    if (data) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setDomain(data)
    setModal(true)
  }

  const updateDomainList = async () => {
    let url = `${BACKEND_URL}/lander/test`
    let { data } = await axios.get(url)

    console.log("url::", url)
    console.log("data::", data)

  }

  useEffect(() => {

    getData()
    getPublisherList()


  }, [])



  useEffect(() => {
    // Define la función que realiza la verificación del endpoint
    const verificarEndpoint = async () => {
      try {
        // Realiza la petición al endpoint

        const url = "https://workspace.policybind.com/api/lander/progress"

        // const url = "https://workspace.policybind.com/api/lander/progress"

        // const url = "http://localhost:8083/lander/progress"

        const respuesta = await fetch(url);

        const datos = await respuesta.json();
        console.log("verificarEndpoint::", datos)
        const { data } = datos
        // Actualiza el estado con los datos obtenidos
        setDatos(data);
      } catch (error) {
        console.error('Error al verificar el endpoint:', error);
      }
    };

    // Establece la verificación cada cierto tiempo (en milisegundos)
    const intervalo = setInterval(verificarEndpoint, 5000); // Verifica cada 5 segundos

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalo);
  }, []);





  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      // {
      //   Header: "Id",
      //   accessor: "id",
      //   disableFilters: true,
      //   hidden: true,
      //   Cell: cellProps => {
      //     return <></>;
      //   },
      // },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return <Actions {...cellProps} onEdit={addModal} onDelete={deleteById} />;
        },
      },
      {
        Header: "Domain Name",
        accessor: "domain_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DomainName {...cellProps} />;
        },
      },
      {
        Header: "Hosted",
        accessor: "hosted",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Hosted {...cellProps} />;
        },
      },
      {
        Header: "Pub Exclusive",
        accessor: "pub_exclusive",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PubExclusive {...cellProps} />;
        },
      },
      {
        Header: "Publisher Name",
        accessor: "publisher_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PublisherName {...cellProps} />;
        },
      },
      {
        Header: "Registrar",
        accessor: "registrar",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Registrar {...cellProps} />;
        },
      },
      {
        Header: "Landers Count",
        accessor: "landers",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <LandersCount {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "available",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Domains config")}
            breadcrumbItem={props.t("Domains config")}
          />
          <Row style={{ margin: 10 }}>
            <Col sm="12">
              <Card>
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2" style={{ flex: 4 }} >Domains List</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 2 }}>
                      <CSVExportButton data={filteredList} filename={"Domains List"} />
                      <Button style={{ madtinRigth: "20px", backgroundColor: "#d74247", borderColor: "#d74247" }}
                        onClick={() => { updateDomainList() }}
                      >
                        <i className='bx bxs-time' ></i>{" Update Domain List"}
                      </Button>
                      <Button style={{ madtinRigth: "20px", backgroundColor: "#d74247", borderColor: "#d74247" }}
                        onClick={() => { addModal() }}
                      >
                        <i className='bx bxs-plus-circle' ></i>{" Add New Domain"}
                      </Button>
                    </div>
                  </div>
                  <Row className="mb-3">
                    <Col sm="4">
                      <div>
                        <label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          Search
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="search"
                            value={search}  // Usamos el valor del estado para controlar el input
                            onChange={handleSearchChange}  // Llamamos a la función para manejar el cambio
                          />
                        </div>
                      </div>
                    </Col>
                    <Col sm="4"> {/* Agrega una columna adicional vacía con sm="4" para crear un espacio */}
                      {/* Este espacio de 4 columnas actúa como un espacio entre las dos columnas */}
                    </Col>
                    <Col className="text-right"> {/* Utiliza la clase text-right para alinear a la derecha */}
                      <div>
                        {datos && <Card style={{ border: '1px solid gray', marginTop: 10, boxShadow: 'none' }}>
                          <CardBody style={{ padding: 5 }}>
                            <CardText>
                              <Progress data={datos} />
                            </CardText>
                          </CardBody>
                        </Card>}
                      </div>
                    </Col>
                  </Row>
                  <TableContainer
                    columns={columns}
                    data={filteredList}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={15}
                  /> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <EditModal
        toggle={toggle}
        isOpen={modal}
        domain={domain}
        isEdit={isEdit}
        domainList={domainList}
        publisherList={publisherList}
        onSubmit={async (value) => {
          if (isEdit) {
            console.log("value", value)
            await onUpdate(value)
          } else {
            await onCreate(value)
          }
          setDomain(null)
          setModal(false)
        }}
      />
    </React.Fragment>
  );
};

DomainConfig.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(DomainConfig)));

