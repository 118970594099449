import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import { withRouter } from "react-router-dom"


//i18n
import { withTranslation } from "react-i18next";

const PercentageCompleted = (props) => {

    const { item, _processList } = props
    const { _id } = item;

    const [completed, setCompleted] = useState(null);
    const [total, setTotal] = useState(null);

    useEffect(() => {

        const element = _processList?.find(item => item._id === _id);
        if(element){
            const { processName, info, status, done, createdAt, updatedAt, completionDate } = element;
            const { completed, total, type, progressPercentage, progressTotal, progresscount } = info
    
            const porsentage = (type=="Disposition" || type=="DispositionOpportunity")?Math.trunc(progressPercentage):Math.trunc(((completed) / total) * 100)

            setCompleted(porsentage)
            
        }

    }, [_processList])


    return (
        <React.Fragment>
           {`${completed}/100`}
        </React.Fragment>
    );
};

PercentageCompleted.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ ProcessMonitor }) => {

    const _processList = ProcessMonitor?.processList

    return {
        _processList,
    };
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(PercentageCompleted)));

